// PrivacyPolicy.jsx
import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="container mx-auto px-7 lg:px-20">
      <section className="p-8 lg:p-16 background mt-0 lg:mt-[-4rem] lg:pt-28">
        <div className="flex flex-col lg:flex-row lg:gap-16">
          <div className="flex-1">
            <p className="text-[10px] md:text-sm text-black flex justify-start items-center font-bold">
              Home /{" "}
              <span className="text-white bg-[#7c44af] px-2 py-1 rounded text-[10px] md:text-sm ml-2">
                {" "}
                Privacy Policy
              </span>
            </p>
            <div className="flex justify-start lg:justify-start gap-2">
              <h1 className="font-extrabold uppercase text-2xl lg:text-right lg:text-6xl mt-2 multi_color_heading">
                Privacy Policy
              </h1>
            </div>
          </div>
        </div>
      </section>
      <div className="container mx-auto px-4 py-12 max-w-4xl">
        <div className="">
          <p className="text-gray-600 mb-4">
            <span className="font-semibold">Effective Date:</span> [01-April-25]
            <br />
            <span className="font-semibold">Last Updated:</span> [31-March-25]
          </p>
          <p className="text-gray-700 mb-6">
            <span className="font-semibold">Webybuild</span> ("Company," "we,"
            "our," or "us") is committed to protecting your privacy. This
            Privacy Policy explains how we collect, use, disclose, and safeguard
            your information when you visit our website and use our services.
            Please read this policy carefully. If you do not agree with the
            terms, please do not use our services.
          </p>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">
              1. Information We Collect
            </h2>
            <p className="text-gray-700 mb-4">
              We may collect the following types of information:
            </p>
            <ul className="list-disc pl-6 text-gray-700 space-y-2">
              <li>
                <span className="font-semibold">Personal Information:</span>{" "}
                Name, email address, phone number, billing details, company
                name, job title, and any other information you provide when
                contacting us or using our services.
              </li>
              <li>
                <span className="font-semibold">Account Information:</span> If
                you create an account with us, we may collect login credentials,
                user preferences, and other account-related details.
              </li>
              <li>
                <span className="font-semibold">Payment Information:</span> When
                you make purchases, we collect payment details (e.g.,
                credit/debit card information, billing address) through
                third-party payment processors.
              </li>
              <li>
                <span className="font-semibold">Usage Data:</span> Information
                about how you interact with our website and services, including
                IP addresses, device type, operating system, browser type,
                referral sources, pages visited, and time spent on our site.
              </li>
              <li>
                <span className="font-semibold">
                  Cookies and Tracking Technologies:
                </span>{" "}
                We use cookies, web beacons, and similar technologies to enhance
                user experience, analyze website performance, and improve our
                services. Users can manage cookie preferences through their
                browser settings.
              </li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">
              2. How We Use Your Information
            </h2>
            <p className="text-gray-700 mb-4">
              We may use the collected information for the following purposes:
            </p>
            <ul className="list-disc pl-6 text-gray-700 space-y-2">
              <li>
                <span className="font-semibold">Service Delivery:</span> To
                provide, operate, and improve our services, including web
                development, hosting, and consulting.
              </li>
              <li>
                <span className="font-semibold">Communication:</span> To send
                updates, promotional materials, service-related notifications,
                and respond to inquiries.
              </li>
              <li>
                <span className="font-semibold">Personalization:</span> To
                tailor content, recommendations, and user experience based on
                individual preferences.
              </li>
              <li>
                <span className="font-semibold">Transactions:</span> To process
                payments, issue invoices, and manage customer accounts.
              </li>
              <li>
                <span className="font-semibold">
                  Security & Fraud Prevention:
                </span>{" "}
                To prevent fraudulent activities, unauthorized access, and
                ensure the integrity of our platform.
              </li>
              <li>
                <span className="font-semibold">Legal Compliance:</span> To
                comply with applicable laws, regulations, and enforce our terms
                of service.
              </li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">
              3. Sharing Your Information
            </h2>
            <p className="text-gray-700 mb-4">
              We do not sell, trade, or rent your personal information. However,
              we may share your data under the following circumstances:
            </p>
            <ul className="list-disc pl-6 text-gray-700 space-y-2">
              <li>
                <span className="font-semibold">Service Providers:</span> We may
                share your information with third-party vendors who assist in
                operating our business (e.g., cloud hosting providers, payment
                processors, marketing partners, and analytics services).
              </li>
              <li>
                <span className="font-semibold">Legal Requirements:</span> If
                required by law, court order, or government authorities, we may
                disclose your information to comply with legal obligations or
                protect our legal rights.
              </li>
              <li>
                <span className="font-semibold">Business Transfers:</span> In
                the event of a merger, acquisition, or sale of assets, your
                information may be transferred to the successor entity, subject
                to the same privacy commitments.
              </li>
              <li>
                <span className="font-semibold">Protection of Rights:</span> If
                necessary, we may disclose information to enforce our policies,
                protect our customers, and prevent fraud or security threats.
              </li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">
              4. Data Security
            </h2>
            <p className="text-gray-700 mb-4">
              We implement industry-standard security measures to protect your
              data, including:
            </p>
            <ul className="list-disc pl-6 text-gray-700 space-y-2">
              <li>
                <span className="font-semibold">Encryption:</span> We use
                SSL/TLS encryption for data transmission.
              </li>
              <li>
                <span className="font-semibold">Access Controls:</span>{" "}
                Restricted access to personal data on a need-to-know basis.
              </li>
              <li>
                <span className="font-semibold">Regular Audits:</span> Routine
                security checks and updates to prevent vulnerabilities.
              </li>
              <li>
                <span className="font-semibold">Data Minimization:</span> We
                collect only the necessary data required for service delivery
                and legal compliance.
              </li>
            </ul>
            <p className="text-gray-700 mt-4">
              However, no method of transmission over the internet is 100%
              secure. You use our services at your own risk.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">
              5. Your Rights and Choices
            </h2>
            <p className="text-gray-700 mb-4">
              Depending on your jurisdiction, you may have the following rights
              regarding your personal data:
            </p>
            <ul className="list-disc pl-6 text-gray-700 space-y-2">
              <li>
                <span className="font-semibold">Access & Correction:</span> You
                can request access to your personal information and correct
                inaccuracies.
              </li>
              <li>
                <span className="font-semibold">Opt-Out:</span> You can opt-out
                of marketing communications at any time by following the
                unsubscribe instructions.
              </li>
              <li>
                <span className="font-semibold">Data Deletion:</span> You may
                request the deletion of your personal data, subject to legal
                limitations.
              </li>
              <li>
                <span className="font-semibold">Restrict Processing:</span> You
                may request restrictions on how we process your data under
                certain circumstances.
              </li>
              <li>
                <span className="font-semibold">Data Portability:</span> You may
                request a copy of your data in a structured, machine-readable
                format.
              </li>
            </ul>
            <p className="text-gray-700 mt-4">
              To exercise these rights, contact us at{" "}
              <a
                href="mailto:hello@webybuild.com"
                className="text-blue-600 hover:underline"
              >
                hello@webybuild.com
              </a>
              .
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">
              6. Third-Party Links
            </h2>
            <p className="text-gray-700">
              Our website may contain links to third-party websites. We are not
              responsible for their privacy practices and encourage you to
              review their policies before providing any personal information.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">
              7. Children's Privacy
            </h2>
            <p className="text-gray-700">
              Our services are not intended for children under 13. We do not
              knowingly collect data from children. If we discover that a child
              has provided personal information, we will take steps to delete
              it.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">
              8. Cookies and Tracking Technologies
            </h2>
            <p className="text-gray-700 mb-4">
              We use cookies and tracking technologies for:
            </p>
            <ul className="list-disc pl-6 text-gray-700 space-y-2">
              <li>
                <span className="font-semibold">Performance & Analytics:</span>{" "}
                Understanding user behavior and improving site functionality.
              </li>
              <li>
                <span className="font-semibold">Advertising:</span> Delivering
                targeted ads based on user interests.
              </li>
              <li>
                <span className="font-semibold">
                  Authentication & Security:
                </span>{" "}
                Enhancing account security and preventing fraudulent activities.
              </li>
            </ul>
            <p className="text-gray-700 mt-4">
              Users can manage or disable cookies through their browser
              settings. Disabling cookies may impact site functionality.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">
              9. Changes to This Policy
            </h2>
            <p className="text-gray-700">
              We may update this Privacy Policy from time to time. Changes will
              be posted on this page, and we will update the "Last Updated"
              date. We encourage users to review this policy periodically.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">
              10. International Data Transfers
            </h2>
            <p className="text-gray-700">
              If you access our services from outside India, your information
              may be transferred to and processed in a country with different
              data protection laws. By using our services, you consent to such
              transfers.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">
              11. Contact Us
            </h2>
            <p className="text-gray-700">
              If you have any questions about this Privacy Policy, contact us
              at:
            </p>
            <p className="text-gray-700 mt-2">
              <span className="font-semibold">Webybuild</span>
              <br />
              India
              <br />
              <a
                href="mailto:hello@webybuild.com"
                className="text-blue-600 hover:underline"
              >
                hello@webybuild.com
              </a>
              <br />
            </p>
          </section>

          <p className="text-gray-700 mt-6 italic">
            By using our website and services, you agree to this Privacy Policy.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;

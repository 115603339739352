// CancellationAndRefund.jsx
import React from "react";

const CancellationAndRefund = () => {
  return (
    <div className="container mx-auto px-7 lg:px-20">
      <section className="p-8 lg:p-16 background mt-0 lg:mt-[-4rem] lg:pt-28">
        <div className="flex flex-col lg:flex-row lg:gap-16">
          <div className="flex-1">
            <p className="text-[10px] md:text-sm text-black flex justify-start items-center font-bold">
              Home /{" "}
              <span className="text-white bg-[#7c44af] px-2 py-1 rounded text-[10px] md:text-sm ml-2">
                Cancellation & Refund
              </span>
            </p>
            <div className="flex justify-start lg:justify-start gap-2">
              <h1 className="font-extrabold uppercase text-2xl lg:text-right lg:text-6xl mt-2 multi_color_heading">
                Cancellation & Refund
              </h1>
            </div>
          </div>
        </div>
      </section>
      <div className="container mx-auto px-4 py-12 max-w-4xl">
        <div className="">
          <p className="text-gray-600 mb-4">
            <span className="font-semibold">Effective Date:</span> [01-April-25]
            <br />
            <span className="font-semibold">Last Updated:</span> [31-March-25]
          </p>
          <p className="text-gray-700 mb-6">
            This Cancellation and Refund Policy outlines the terms under which
            Webybuild ("we," "us," or "our") handles cancellations and refunds
            for our website development and related services. By engaging our
            services, you ("client," "you," or "your") agree to these terms.
            Please review this policy carefully before proceeding with your
            project.
          </p>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">
              1. Cancellation by the Client
            </h2>
            <ul className="list-disc pl-6 text-gray-700 space-y-2">
              <li>
                You may cancel your project at any time by providing written
                notice via email to{" "}
                <span className="font-bold">hello@webybuild.com</span>. Verbal
                cancellations will not be accepted unless followed by written
                confirmation.
              </li>
              <li>
                If cancellation occurs before work begins (i.e., prior to the
                initial 50% payment or project kickoff), no fees will be
                charged, and any preliminary discussions or quotes will be
                considered void.
              </li>
              <li>
                If cancellation occurs after work has commenced (i.e., after the
                initial 50% payment and project kickoff), the initial payment is
                non-refundable. Webybuild will provide you with any completed
                work up to the cancellation date, such as design drafts or
                partial code, at our discretion.
              </li>
              <li>
                For cancellations after the project is 50% or more complete
                (based on agreed milestones), you will be invoiced for the work
                completed to date, calculated at our standard hourly rate of $75
                or as a percentage of the total project cost, whichever is
                higher. This invoice is due within seven (7) days of issuance.
              </li>
              <li>
                Cancellation does not relieve you of the obligation to pay for
                third-party services (e.g., domain registration, hosting, or
                premium plugins) already purchased on your behalf.
              </li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">
              2. Cancellation by Webybuild
            </h2>
            <ul className="list-disc pl-6 text-gray-700 space-y-2">
              <li>
                Webybuild reserves the right to cancel a project at any time
                under the following circumstances:
                <ul className="list-circle pl-6 mt-2 space-y-1">
                  <li>
                    Non-payment of invoices beyond 14 days from the due date,
                    after a written reminder.
                  </li>
                  <li>
                    Failure to provide required content or feedback within 30
                    days of request, causing unreasonable delays.
                  </li>
                  <li>
                    Abusive, threatening, or unethical behavior toward our
                    staff.
                  </li>
                  <li>
                    Use of our services for illegal, immoral, or prohibited
                    purposes.
                  </li>
                </ul>
              </li>
              <li>
                In the event of cancellation by Webybuild, we will provide
                written notice via email, specifying the reason for termination.
              </li>
              <li>
                If we cancel due to client breach, no refunds will be issued for
                payments already made, and you will be invoiced for any
                outstanding work completed up to the cancellation date.
              </li>
              <li>
                If we cancel for reasons unrelated to client breach (e.g.,
                unforeseen business closure), we will refund any unused portion
                of payments, prorated based on work completed, within 30 days.
              </li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">
              3. Refund Policy
            </h2>
            <ul className="list-disc pl-6 text-gray-700 space-y-2">
              <li>
                <span className="font-semibold">General Rule:</span> All
                payments made to Webybuild, including the initial 50% deposit
                and subsequent payments, are non-refundable once work has
                commenced, except as outlined below.
              </li>
              <li>
                <span className="font-semibold">Pre-Work Refund:</span> If you
                pay the initial 50% deposit but cancel before any work begins, a
                full refund will be issued within 14 business days, provided no
                resources (e.g., third-party purchases) have been allocated.
              </li>
              <li>
                <span className="font-semibold">Post-Completion Refund:</span>{" "}
                No refunds will be provided for completed projects once the
                website is uploaded to the server or materials are released,
                even if you choose not to use the final product.
              </li>
              <li>
                <span className="font-semibold">Third-Party Costs:</span>{" "}
                Refunds do not cover costs for third-party services (e.g., SSL
                certificates, hosting, domain registration) purchased on your
                behalf. These are your responsibility to manage or cancel
                directly with the provider.
              </li>
              <li>
                <span className="font-semibold">
                  Exceptional Circumstances:
                </span>{" "}
                In rare cases (e.g., failure to deliver agreed-upon services due
                to Webybuild’s negligence), refunds may be considered at our
                discretion. You must submit a written request within 30 days of
                project completion, detailing the issue. Refunds, if approved,
                will be prorated based on work completed and issued within 30
                days of approval.
              </li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">
              4. Project Abandonment
            </h2>
            <ul className="list-disc pl-6 text-gray-700 space-y-2">
              <li>
                If you fail to respond to requests for content, feedback, or
                payment within 60 days of our last communication, the project
                will be considered abandoned.
              </li>
              <li>
                For abandoned projects, no refunds will be issued for payments
                already made, and Webybuild reserves the right to either:
                <ul className="list-circle pl-6 mt-2 space-y-1">
                  <li>
                    Retain all work completed without delivery, with no further
                    obligation to you.
                  </li>
                  <li>
                    Complete and deploy the website using placeholder content,
                    at our discretion, after which full payment will be due.
                  </li>
                </ul>
              </li>
              <li>
                To reactivate an abandoned project, you must pay a reactivation
                fee of $150 plus any outstanding invoices, subject to our
                availability.
              </li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">
              5. Force Majeure
            </h2>
            <p className="text-gray-700">
              Webybuild is not liable for delays, cancellations, or failure to
              deliver services due to circumstances beyond our control,
              including but not limited to natural disasters, government
              actions, power outages, or internet failures. In such cases, no
              refunds will be issued unless we are unable to resume work within
              90 days, at which point a prorated refund may be considered at our
              discretion.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">
              6. Dispute Resolution
            </h2>
            <ul className="list-disc pl-6 text-gray-700 space-y-2">
              <li>
                If you dispute a cancellation or refund decision, please contact
                us at <span className="font-bold">hello@webybuild.com</span>{" "}
                within 14 days of the decision to initiate a review. We will
                attempt to resolve the matter through negotiation.
              </li>
              <li>
                If negotiation fails, disputes will be resolved through binding
                arbitration in [Bihar], governed by the laws of [India], with
                each party bearing their own costs.
              </li>
              <li>
                Legal action, if necessary, will be filed in the courts of
                **Patna, Bihar, India**.
              </li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">
              7. Changes to This Policy
            </h2>
            <p className="text-gray-700">
              Webybuild may update this Cancellation and Refund Policy at any
              time. Changes will be posted on this page with an updated "Last
              Updated" date. Continued engagement with our services after
              changes constitutes acceptance of the revised policy.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">
              8. Contact Us
            </h2>
            <p className="text-gray-700">
              For questions, cancellation requests, or refund inquiries, contact
              us at:
              <br />
              <span className="font-semibold">Webybuild</span>
              <br />
              India
              <br />
              <a
                href="mailto:hello@webybuild.com"
                className="text-blue-600 hover:underline"
              >
                hello@webybuild.com
              </a>
              <br />
            </p>
          </section>

          <p className="text-gray-700 mt-6 italic">
            By engaging our services, you agree to this Cancellation and Refund
            Policy.
          </p>
        </div>
      </div>
    </div>
  );
};

export default CancellationAndRefund;

// TermsAndConditions.jsx
import React from "react";

const TermsAndConditions = () => {
  return (
    <div className="container mx-auto px-7 lg:px-20">
      <section className="p-8 lg:p-16 background mt-0 lg:mt-[-4rem] lg:pt-28">
        <div className="flex flex-col lg:flex-row lg:gap-16">
          <div className="flex-1">
            <p className="text-[10px] md:text-sm text-black flex justify-start items-center font-bold">
              Home /{" "}
              <span className="text-white bg-[#7c44af] px-2 py-1 rounded text-[10px] md:text-sm ml-2">
                Terms & Conditions
              </span>
            </p>
            <div className="flex justify-start lg:justify-start gap-2">
              <h1 className="font-extrabold uppercase text-2xl lg:text-right lg:text-6xl mt-2 multi_color_heading">
                Terms & Conditions
              </h1>
            </div>
          </div>
        </div>
      </section>
      <div className="container mx-auto px-4 py-12 max-w-4xl">
        <div className="">
          <p className="text-gray-600 mb-4">
            <span className="font-semibold">Effective Date:</span> [01-April-25]
            <br />
            <span className="font-semibold">Last Updated:</span> [31-March-25]
          </p>
          <p className="text-gray-700 mb-6">
            By accepting a quote, you agree to and accept the terms and
            conditions of Webybuild. Acceptance can be verbal, by email, payment
            of initiation, or signing a quote. These Terms & Conditions govern
            the relationship between Webybuild ("we," "us," or "our") and you
            ("client," "you," or "your") regarding the provision of website
            development and related services.
          </p>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">
              1. Payment Terms
            </h2>
            <ul className="list-disc pl-6 text-gray-700 space-y-2">
              <li>
                To start working on your website design, we need an upfront
                payment of at least fifty (50) percent of the total cost
                mentioned in the project quotation. After we finish the work,
                the remaining fifty (50) percent is due before we upload the
                website to the server or release any materials.
              </li>
              <li>
                Payments must be made within seven (7) days of the invoice date
                unless otherwise agreed in writing. Late payments may incur a
                penalty of 1.5% per month on the outstanding balance.
              </li>
              <li>
                All payments are non-refundable once work has commenced, except
                as outlined in Section 8 (Termination).
              </li>
              <li>
                Additional services or scope changes requested mid-project will
                be invoiced separately and are due upon receipt unless otherwise
                specified.
              </li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">
              2. Project Timelines & Client Responsibilities
            </h2>
            <ul className="list-disc pl-6 text-gray-700 space-y-2">
              <li>
                Any delays at the client’s end may delay the project and
                proposed timeframes and may incur additional costs.
              </li>
              <li>
                Website/application content and all related materials need to be
                provided to us within the first two weeks of starting the
                project. Any delays thereafter may delay the project and may
                incur additional charges of $50 per week if it goes beyond a
                reasonable timeframe.
              </li>
              <li>
                Our websites/applications are generally tested on PCs and
                include near recent versions of the following browsers: IE,
                Firefox, Chrome, and Safari. If you require testing to be done
                on any other browser, please let us know in advance, and it may
                incur extra fees.
              </li>
              <li>
                Responsive/multi-device compatible web pages are tested on PC
                and Android devices. If you require testing to be done on any
                other device (e.g., iOS, tablets), please discuss it in advance,
                and it may involve additional costs.
              </li>
              <li>
                Clients are responsible for providing accurate, complete, and
                legally compliant content. Webybuild is not liable for errors,
                omissions, or legal issues arising from client-provided
                materials.
              </li>
              <li>
                Project timelines are estimates and subject to change based on
                client responsiveness, complexity of revisions, or unforeseen
                technical challenges, particularly with the integration of
                third-party software. The client agrees not to penalize us for
                any genuine delay when every effort is made to keep the project
                on schedule, as long as it is within a reasonable period.
              </li>
              <li>
                The client must clarify before initiating the project who will
                be the designated decision-maker for website changes. Webybuild
                will only follow instructions from this individual, and requests
                from other team members will be denied.
              </li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">
              3. Changes, Updates & Bug Fixes
            </h2>
            <ul className="list-disc pl-6 text-gray-700 space-y-2">
              <li>
                Any bugs (programming errors) reported during or just after the
                development do not attract additional charges and will be fixed
                within 30 days of project completion at no additional cost.
              </li>
              <li>
                Any changes or updates made to a task that has already been
                completed will not result in any additional charges for the
                lifetime of the project. This includes adding or updating
                content, adding images to existing sections, updating offers,
                contacts, and addresses, provided they do not alter the original
                design or functionality scope.
              </li>
              <li>
                Any changes in the design or functional enhancements after the
                design approval will incur additional charges, quoted at an
                hourly rate of $75 or as a fixed fee based on scope.
              </li>
              <li>
                Major revisions requiring more than 25% of the original project
                effort will be treated as a new project with separate pricing
                and timelines.
              </li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">
              4. Copyright & Code Ownership
            </h2>
            <ul className="list-disc pl-6 text-gray-700 space-y-2">
              <li>
                Webybuild software codes (not including open-source software)
                are copyrights of Webybuild. The codes can be handed over at an
                additional cost for use once all previous invoices are settled,
                on the condition that the codes are used only for use or
                modification for re-use for further development for the
                specified client or subsequent owners of that legal entity.
                Under no circumstance will the codes be allowed to be used for
                reselling or duplication purposes. Once the code is provided
                upon request, the client must sign a Code Handover Agreement.
                After the handover is complete, Webybuild is not responsible for
                any bugs that arise in the code or for fixing the website code.
              </li>
              <li>
                Upon full payment, clients receive a non-exclusive, perpetual
                license to use the website for their intended purpose.
              </li>
              <li>
                Webybuild reserves the right to reuse generic code snippets or
                solutions developed during the project in other works, provided
                they do not contain client-specific data or designs.
              </li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">
              5. Hosting & Third-Party Services
            </h2>
            <ul className="list-disc pl-6 text-gray-700 space-y-2">
              <li>
                Hosting charges are not included in the quotations unless
                mentioned otherwise. Webybuild can organize an appropriate
                hosting solution if required, a quote for which will be
                submitted separately and approved by the client.
              </li>
              <li>
                Where clients decide to organize their own hosting, we should be
                consulted before finalizing the type of hosting and database, as
                it should meet the requirements of the technology used for the
                development. We’ll require full access with hosting support for
                testing and deploying the application. Webybuild will not be
                liable for any delays or errors caused by direct or indirect
                actions of the hosting company.
              </li>
              <li>
                Depending upon the functionalities required, there may be 3rd
                party components such as Third-Party Payment Gateways or SSL
                certificates involved in building a website/application.
                Although Webybuild does its best in recognizing the suitability
                of any such component, any unforeseen limitations of 3rd party
                components are beyond our control. Any third-party component
                purchase costs (such as SSL, Payment Gateway, Google AdWords,
                Plug-in licenses, etc.) are not included in our quotes. SSL can
                be provided for free for a lifetime if we have remaining tokens.
              </li>
              <li>
                Domain registration/renewal etc. charges are not included as a
                part of any project/proposal unless mentioned otherwise. If
                required, a quote for which will be submitted separately and
                approved by the client.
              </li>
              <li>
                Any integration with third-party services (e.g., APIs, CRMs) is
                subject to compatibility and may incur additional fees if custom
                development is required.
              </li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">
              6. Portfolio & Attribution
            </h2>
            <ul className="list-disc pl-6 text-gray-700 space-y-2">
              <li>
                A link to Webybuild will appear in either small type or by a
                small graphic at the bottom of the Client's website unless
                otherwise agreed in writing. If a graphic is used, it will be
                designed to fit in with the overall site design. Removal of this
                attribution requires a one-time fee of $100.
              </li>
              <li>
                The Client also agrees that the website developed for the Client
                may be presented in Webybuild’s portfolio, including screenshots
                and descriptions, unless confidentiality is explicitly requested
                in advance.
              </li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">
              7. Client Data & Copyrights
            </h2>
            <ul className="list-disc pl-6 text-gray-700 space-y-2">
              <li>
                The Client keeps the ownership of data, files, and graphic logos
                they provide. They allow Webybuild to publish and use that
                material. If the Client wants to use any copyrighted information
                or files from a third party, they need to get permission and
                rights. It is the Client's responsibility to grant Webybuild
                permission and rights to use that material. The Client agrees to
                protect Webybuild from any claims that result from their
                negligence or failure to obtain proper copyright permissions.
                When the Client signs a contract for website design or
                placement, it guarantees that they have obtained all necessary
                permissions and authorities. Webybuild may ask for evidence of
                these permissions and authorities.
              </li>
              <li>
                Clients warrant that all provided materials are either owned by
                them or licensed for use, and indemnify Webybuild against any
                legal action resulting from their use.
              </li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">
              8. Termination
            </h2>
            <ul className="list-disc pl-6 text-gray-700 space-y-2">
              <li>
                Either party may terminate the project by providing written
                notice if the other party breaches these terms and fails to
                remedy the breach within 14 days.
              </li>
              <li>
                If the client terminates the project after work has begun, no
                refunds will be issued for payments already made. Webybuild will
                deliver any completed work up to the termination date.
              </li>
              <li>
                Webybuild reserves the right to terminate services for
                non-payment, abusive behavior, or illegal use of the website,
                with no obligation to refund prior payments.
              </li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">
              9. Warranties & Liability
            </h2>
            <ul className="list-disc pl-6 text-gray-700 space-y-2">
              <li>
                Webybuild warrants that services will be performed with
                reasonable skill and care. Websites are provided "as is" after
                the 30-day bug fix period, with no further warranties, express
                or implied.
              </li>
              <li>
                Webybuild is not liable for indirect, incidental, or
                consequential damages, including lost profits, data, or business
                opportunities, arising from the use of our services.
              </li>
              <li>
                Total liability is limited to the amount paid by the client for
                the specific project in question.
              </li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">
              10. Confidentiality
            </h2>
            <ul className="list-disc pl-6 text-gray-700 space-y-2">
              <li>
                Webybuild will treat all client-provided information as
                confidential and will not disclose it to third parties except as
                required to perform the services or by law.
              </li>
              <li>
                Clients agree not to disclose Webybuild’s proprietary methods,
                pricing, or processes shared during the project.
              </li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">
              11. Communication
            </h2>
            <p className="text-gray-700">
              All communications/correspondences are generally done via emails.
              It is the client’s responsibility to keep us updated with their
              relevant email addresses.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">
              12. Force Majeure
            </h2>
            <p className="text-gray-700">
              Webybuild is not liable for delays or failure to perform due to
              circumstances beyond our control, including but not limited to
              natural disasters, government actions, power outages, or internet
              failures. We will try our best to complete the project as agreed
              in the proposal despite such events.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">
              13. Governing Law & Dispute Resolution
            </h2>
            <ul className="list-disc pl-6 text-gray-700 space-y-2">
              <li>
                These Terms & Conditions are governed by the laws of [India].
                Any disputes will be resolved through negotiation, and if
                unsuccessful, through binding arbitration in [Bihar], with each
                party bearing their own costs.
              </li>
              <li>
                Legal action, if necessary, will be filed in the courts of
                **Patna, Bihar, India**.
              </li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">
              14. Changes to Terms
            </h2>
            <p className="text-gray-700">
              Webybuild may update these Terms & Conditions at any time. Changes
              will be posted on this page with an updated "Last Updated" date.
              Continued use of our services after changes constitutes acceptance
              of the revised terms.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">
              15. Contact Us
            </h2>
            <p className="text-gray-700">
              For questions or concerns about these Terms & Conditions, contact
              us at:
              <br />
              <span className="font-semibold">Webybuild</span>
              <br />
              India
              <br />
              <a
                href="mailto:hello@webybuild.com"
                className="text-blue-600 hover:underline"
              >
                hello@webybuild.com
              </a>
              <br />
            </p>
          </section>

          <p className="text-gray-700 mt-6 italic">
            By using our services, you agree to these Terms & Conditions.
          </p>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
